.usercheck{
    color :white;
font-size: 15px;
padding: 10px;
}
.teamclass{
    width: 100%;
    padding: 9px;
    margin-top: 10px;
    font-size: 15px;
    border-radius: 6px;
}
.teamedit{
    background-color: #10142c;
    text-align: center;
    color: white;
}
.teamclass1{
    background-color: #23354d;
    border: none;
}
.about-club-dt-define {
    width: 100%;
    flex-shrink: 0;
    height: 122px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    overflow: visible;
  }
  .about-club-dt-define-name{
    font-weight: 400;
    font-style: normal;
    font-family: "Noto Sans", sans-serif;
    color: var(--token-a38dd3d3-b9de-4302-b504-277e6b4a701f, #7890a1) /* {"name":"grey2"} */;
    font-size: 12px;
    letter-spacing: 0px;
    line-height: 1;
    text-transform: capitalize;
    text-align: center;
    margin-top: 10px;
  }
  .text-area-dfin{
    width: 100%;
    height: 96px;
    font-size: 16px;
    color: var(--white, #ffffff);
    border-radius: 6px;
    padding: 10px;
    margin-top: 5px;
    background-color: var(--token-e2079bf0-f281-429e-90ac-e5958a3c1a75, #23354d);
  }
  .gap_12px{
    gap: 12px !important;
    padding-left: 18px;
    padding-right: 18px;
    border-bottom: 1px solid #23354d;
    padding-bottom: 18px;
  }
  .gap_12px12{
    gap: 12px !important;
    padding-left: 9px;
    padding-right: 9px;
    border-bottom: 1px solid #23354d;
    padding-bottom: 18px;
  }
  .color_With_Padding_18px{
    padding-bottom: 9px;
    font-size: 12px;
    color: white;
    border-bottom: 1px solid #23354d;

  }
  .padding_left_18px{
    padding-left: 18px;
  }
  .text_Align_Crnter_Item{
align-items: center;

  }
  .color_789_define{
color: #7890a1;
display: flex;
align-items: center;
  }
  .padding_top_18px{
    padding-top: 18px;
  }
  .border_profile_Add_item {
    border: 1px solid #23354d;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    color: #7890a1;
    transition: color 0.3s ease; /* Optional: smooth transition */
  }
  
  .border_profile_Add_item:hover {
    color: #33a4ff;
  }
  
  .gap_16px_Devide{
    gap: 16px;
  }
  .color_Define_Always_Depend{
    color: #7890a1 !important;
  }