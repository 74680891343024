.tapping_login{
    flex-shrink: 0;
    width: 100%;
 
    overflow: hidden;
    white-space: pre-wrap;
    word-wrap: break-word;
    word-break: break-word;
    font-weight: 400;
    font-style: normal;
    font-family: "Noto Sans", sans-serif;
    color: #7890a1 ;
    font-size: 14px;
    letter-spacing: 0px;
    line-height: 1.2;
    text-align: center;
  }
  .phone_login {
    box-sizing: border-box;
    flex-shrink: 0;
    width: 250px;
    height: 45px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 4px 36px 4px 34px;
    background-color: #00bbff;
    overflow: visible;
    border-radius: 38px;
  }
  .tapping_login1{
      width: 100%;
      height: 100vh;
      box-sizing: border-box;
      flex-shrink: 0;
 
  
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      padding: 4px 36px 4px 34px;
    
      overflow: visible;
      border-radius: 38px;
  }
  .l-o-g-i-n-as-guest {
  width: 100%; /* 108px */
  height: 100%; /* 17px */
  flex-shrink: 0;
  overflow: visible;
  white-space: pre;
  font-weight: 400;
  font-style: normal;
  font-family: "Noto Sans", sans-serif;
  color:  #ffffff;
  font-size: 14px;
  background-color:  #00bbff;
  letter-spacing: 0px;
  line-height: 1.2;
  border: none;
  text-align: center;
}