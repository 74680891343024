body {
  margin: auto;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', "Noto Sans", 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #10142c !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@media only screen and (min-width: 600px) {
.width_60_media{
  margin: auto !important;
  max-width: 60%;
}
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .width_70_media{
    margin: auto !important;
    max-width: 70%;
}
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .width_90_media{
    margin: auto !important;
    max-width: 90%;
}
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .width_100_media{
    margin: auto !important;
    max-width: 100%;
}
}









/* 



maxHeight: 'none',
overflowY: 'visible',
overflowX: 'auto',
'&::-webkit-scrollbar': {
  height: '8px',
  width: '0px',
},
'&::-webkit-scrollbar-thumb': {
  background: '#90A4AE',
  borderRadius: '4px',
},
'&::-webkit-scrollbar-thumb:hover': {
  background: '#78909C',
},
'&::-webkit-scrollbar-track': {
  background: '#CFD8DC',
  borderRadius: '4px',
}, */



