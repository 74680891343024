.s-t-a-k-e-genres {
  box-sizing: border-box;
  width: 100%;
  flex-shrink: 0;
  /* height: 237px; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  /* padding: 20px; */
  padding-left: 20px;
  padding-right: 20px;
  /* margin-bottom: 30px; */
  overflow: visible;
}
.s-t-a-k-e-genres_margin{
  box-sizing: border-box;
  width: 100%;
  flex-shrink: 0;
  /* height: 237px; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 20px;
  padding-right: 20px;
  /* margin-bottom: 100px; */
  overflow: visible;
}
.general-info {
  flex-shrink: 0;
  width: 100%;
  /* height: 37px; */
  overflow: visible;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
  font-weight: 700;
  font-style: normal;
  font-family: "Noto Sans", sans-serif;
  color: var(--token-62d928f7-b29b-49a5-a425-bbc990e69ba9, #ffffff)
    /* {"name":"ag_white"} */;
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 1.2;
  text-align: left;
}
.list-serach1 {
  box-sizing: border-box;
  width: 100%;
  flex-shrink: 0;
  height: 36px;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  padding: 0px 0px 0px 7px;
  overflow: visible;
  border-color: var(--token-d34ce7c3-c3ef-41c9-8221-3e1644782e0e, #202337)
    /* {"name":"Ag_grey3"} */;
  border-style: solid;
  text-decoration: none;
  border-top-width: 0px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.list-serach1:hover{
  color: #00bbdd !important;
  background-color: #10142c;
  
}
.about-darto:hover{
  color: #00bbdd !important;
  background-color: none !important;
}
.about-darto {
  width: auto; /* 69px */
  height: auto; /* 14px */
  flex-shrink: 0;
  overflow: visible;
  /* white-space: pre; */
  font-weight: 400;
  font-style: normal;
  font-family: "Noto Sans", sans-serif;
  color: var(--token-2a633678-f86b-4ab5-94be-ee4f65254cde, #7890a1)
    /* {"name":"top_nav_text"} */;
  font-size: 12px;
  letter-spacing: 0px;
  line-height: 1.2;
  text-transform: capitalize;
  text-align: left;
}
.padding_top_bottom_18px{
  padding-top: 9px;
  padding-bottom: 9px;
  padding-left: 9px;
  padding-right: 9px;
}
