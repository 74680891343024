.textcolor{
  color: black;
  font-size: 13px;
}

.button-container-div {
  width: 50vw;
  height: 10vh;
  margin: auto;
}
.textcolor1{
  color: red;
  font-size: 3rem; 
  font-size: 16px;
}
.trnmt{
  padding: 100px;
}
.btnt{
  width: 100%;
  height: 8vh;
  display: block;
  margin-left: auto;
  margin-right: auto;
background-color: red;
margin-top: 9px;
margin-bottom: 10px;
color: white;
font-size: 20px;
background-color:  #fa0043;
border: none;
}
.btnt1{
  width: 50vw;
  height: 8vh;
  display: block;
  margin-left: auto;
  margin-right: auto;
  background-color:  #fa0043;
margin-top: 10px;
margin-bottom: 30px;
color: white;
font-size: 20px;
border: none;
}

.txtcolor3{
  color: gray;
  font-size: 13px;
}

.button-container-div1 {
  width: 50vw;
  height: 10vh;
  margin: auto;
}
.txtcolor2{
  color: white;
  font-size: 16px;
}
.trnmt{
  padding: 100px;
}
.shareit{
  float: right;
}