@media only screen and (max-width: 600px) {
  .header_Home_Devided{
     height: 45px;
     padding-top: 6px;
     text-align:center;
  } 
  .sub_Home_Header{
    height: 54px;
  } 
  .navbarHeader_Home{
    /* margin-left: 0px !important; */
    padding-left: 0px !important;
    margin-bottom: 0px !important;
    height: 54px;
  }
  
  .padding_Botto_Top_d{
    padding-top: 63px;
    /* padding-bottom: 60px; */
}
}
@media only screen and (min-width: 600px) {
.padding_Botto_Top_d{
  padding-top: 63px;
}
}
/* .sub_Home_Header{
  padding-bottom: 100px !important;
} */