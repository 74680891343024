.slideshow {
    margin: 0 auto;
    overflow: hidden;
    max-width: 100%;
    padding-top: 36px;
  }

  
  .slideshowSlider {
    white-space: nowrap;
    transition: ease 2000ms;
  }
  
  .slide {
    display: inline-block;
    /* At he end we have give height in percentage to make it responsive  */
    height: 100%;  
    width: 100%;
    border-radius: 10px;
  }
  
  
  .slideshowDots {
    text-align: center;
  }
  
  .slideshowDot {
    display: inline-block;
    height: 10px;
    width: 10px;
    border-radius: 50%;
  
    cursor: pointer;
    margin: 15px 5px 0px;
  
    background-color: #23354d;
  }
  
  .slideshowDot.active {
    background-color:  black;
  }

  @media only screen and (max-width: 600px) {
    .slideshowDot{
      height: 5px;
      width: 5px;
    }
   
  }
  .carousel-indicators [data-bs-target] {
    box-sizing: content-box;
    flex: 0 1 auto;
    height: 5px !important;
    width: 5px !important;
    border-radius: 50% !important;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    z-index: 10 !important;
    cursor: pointer;
    background-color: #23354d !important;
    background-clip: padding-box;
    border: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: 0.5;
    transition: opacity 0.6s ease;
}

.carousel-control-next-icon {
  display: inline-block;
  width: 4rem !important;
  height: 4rem !important;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}
.carousel-control-prev-icon, .carousel-control-next-icon {
  display: inline-block;
  width: 4rem !important;
  height: 4rem !important;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}