.logo{
    height: 4vw;
    width: 10vw;
    display: block;
  
}





@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,500;1,400&display=swap');

* {
    margin: 0;
    padding: 0;
 
}

html {
    font-size: 35%;
    font-family: 'Roboto', sans-serif;
}

/* li {
    list-style: none;
} */
.background_Nav{
  list-style: none;
}

a {
    text-decoration: none;
}
.header{
  
  /* border-bottom: 1px solid #E2E8F0; */
  background-color: #10142c;
  /* height:9vw; */
  /*   padding-top:100px;height: 10vh; */
  margin-top: 40px;
  position: fixed;
  z-index: 10;
  width: 100%;

}

.heade_subheader_Define_DT{
    /* border-bottom: 1px solid #E2E8F0; */
    background-color: #10142c;
    /* height:9vw; */
    /*   padding-top:100px;height: 10vh; */
    /* margin-top: 50px; */
    position: fixed;
    z-index: 10;
 
}
.navbar1 {
  width: 100%;
  background-color: #10142c;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 1rem 1.5rem; */
  overflow: auto;
 
}
/* @media only screen and (min-width: 600px) {
  .navbar1 {
    width: 60%;
 
   
  }
  .heade_subheader_Define_DT{
    width: 60% ;
  }
.devider_IN_60{
  width: 60%;
  margin: auto;
}

} */
.hamburger {
  display: none;
}

.bar {
  display: block;
  width: 25px;
  height: 3px;
  margin: 5px auto;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: white;
}
.nav-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  
 
}
.nav-menu li:hover{
  color: white;
}
.nav-item {
  cursor: pointer;
  /* margin-left: 1rem; */
  color:#5c778a;
}

.nav-link{
  width: auto; /* 35px */
  height: auto; /* 21px */
  overflow: visible;
  white-space: pre;
  font-weight: 400;
  font-style: normal;
  font-family: "Roboto", sans-serif;
  color: #5c778a;
  font-size: 14px !important;
  letter-spacing: 0px;
  line-height: 1.6;
  text-transform: capitalize;
  text-align: center;
  padding-bottom: 6px !important;
  
  
  
}
.nav-link-paddi{
  color: #5c778a !important;
}
.nav-link-paddi:active{
  color: #00bbff !important;
}

.nav-find-u:hover{
  
  color: white;
  /* background-color: red; */
}
.nav-link:active{
  color: #10142c;

}
/* .nav-link{
  color: ;
} */
/* .underline_Hover{
  padding-bottom: 9px;
} */

.nav-link-paddi:hover{
background-color:#10142c;
border-bottom: 3px solid #00bbff;
color:#00bbff !important;
padding-bottom: 6px !important;
font-size: 14px !important;


/* transition: .5s background-color; */

}

.nav-link-paddi.active{
  color: #00bbff !important;
  border-bottom: 3px solid #00bbff;
}
.nav-logo {
  font-size: 2.1rem;
  font-weight: 500;
  color: #5c778a !important;
}
.background_Nav{
  background-color: #10142c;
}
.nav-menu.ul{
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

@media only screen and (max-width: 600px) {
  .header{
    background-color: #10142c;
  /* margin-top: 54px; */
  /* height: 45px; */
  position: fixed;
  z-index: 10;
    
  }
  .heade_subheader_Define_DT{
    /* border-bottom: 1px solid #E2E8F0; */
    background-color: #10142c;
    /* height:9vw; */
    /*   padding-top:100px;height: 10vh; */
    /* margin-top: 50px; */
    /* top: 0; */
    height: 54px;
    padding-left: 18px;
    padding-right: 18px;
    position: fixed;
    z-index: 10;

}

 }

 @media only screen and (min-width: 600px) {
  .header{
    background-color: #10142c;
  margin-top: 55px;
  height: 45px;
  position: fixed;
  z-index: 10;
  max-width: 60%;
  left: 20%;
    
  }
  .heade_subheader_Define_DT{
    max-width: 60%;
    left: 20%;
  }
}
/* @media only screen and (max-width: 768px) {
  .nav-menu {
      position: fixed;
      left: -100%;
      top: 5rem;
      flex-direction: column;
      background-color: #fff;
      width: 100%;
      border-radius: 10px;
      text-align: center;
      transition: 0.3s;
      
      box-shadow:

          0 10px 27px rgb(0, 0, 0);
  }

  .nav-menu.active {
      left: 0;
      
  }

  .nav-item {
      margin: 2.5rem 0;
  }

  .hamburger {
      display: block;
      cursor: pointer;
  }
  .hamburger.active .bar:nth-child(2) {
    opacity: 0;
}

.hamburger.active .bar:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
}

.hamburger.active .bar:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
}

} */
@media only screen and (min-width: 600px) {

.align_Logo_Manage{
  align-items: center !important;
}

/* .update-opt{
  margin-left: 265px;
} */
}
.padding_In_nav_link{
  padding-left: 18px;
  display: flex;
}
.scroll {
  width: 20px;
  height: 200px;
  overflow: auto;
  float: left;
  margin: 0 10px;
}
.scroll1::-webkit-scrollbar {
  width: 5px;
}
 
.scroll1::-webkit-scrollbar-track {
  background: #ddd;
}
 
.scroll1::-webkit-scrollbar-thumb {
  background: #666; 
}
.bg_main_color{
  background-color: #10142c;
}
.sub_navigation_bar:hover{
background-color: #10142c;
}
.sub_navigation_bar{
  color: #10142c !important;
  text-decoration: none !important;
}