.bg-form-col{

    box-sizing: border-box;
    width: 100%;
    flex-shrink: 0;
    height: 449px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
     align-items: center;  
 
    overflow: visible;
  }
 .bg-form-col1{
    width: auto; /* 112px */
    height: auto; /* 12px */
    flex-shrink: 0;
    overflow: visible;
    white-space: pre;
    font-weight: 400;
    font-style: normal;
    font-family: "Noto Sans", sans-serif;
    color:#7890a1;
    font-size: 14px;
    letter-spacing: 0px;
    line-height: 1;
    text-align: center;
 }
 .bg-form-c9{
   margin-top: 15px;
     font-size: 14px;
     margin-left: 7px;
 }

  .bg-form-col2{
    width: auto; /* 198px */
    flex-shrink: 0;
    height: 36px;
    overflow: hidden;
    white-space: pre;
    font-weight: 700;
    font-style: normal;
    font-family: "Noto Sans", sans-serif;
    color: #00bbff;
    font-size: 18px;
    letter-spacing: 0px;
    line-height: 1;
    text-align: center;
  }
  .month-imp {
    width: auto; /* 279px */
    flex-shrink: 0;
    height: 35px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    overflow: visible;
  }
  .month-imp1 {
    box-sizing: border-box;
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    background-color:  #7890a1;
    overflow: visible;
    opacity: 0.54;
    border-radius: 50%;
    border: 2px solid #000000;
  }
  .month-imp2 {
    box-sizing: border-box;
    flex-shrink: 0;
    width: 216px;
    height: 36px;
    display: flex;
    border: none;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 3px 21px 3px 21px;
    background-color:  #fa0043;
    overflow: visible;
    border-radius: 64px;
    color:  #ffffff;
    font-size: 14px;
  }
  .participate-inp2{
    width: 100%;
    padding: 25px;
    background-color:  rgb(19, 34, 52);
    
  }
  .radio-round-bt{

  width: 20px;
  height: 20px;
  background-color:  #7890a1;
  overflow: visible;
  opacity: 0.54;
  border-radius: 50%;
  
  }