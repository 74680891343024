
  .container1 {
    display: flex;
    width: 100%;
    height: 100%;
   margin-left: 20px;
   margin-right: 20px;
   padding: 20px;
    background-color: #23354d;
    margin: auto;
    
}
.first {
    width: 50%;

    height: 100%;
        background-color: #23354d;
        margin-top: auto;
        margin-bottom: auto;
}
.second {
    width: 50%;
  
    height:100%;
    background-color: #23354d;
}
.clear {
    clear: both;
}
.text {
  
  color: var(--white, #ffffff);
  font-size: 3rem;
    text-align: center;
  }
  .dartos {
    width: 25vw;
    display: block;
    margin-left: auto;
    margin-right: auto; 
    
  }
  .dartosbtn{
    width: 96px;
    height: 36px;
    background-color: var(--token-eff61df6-31e2-4b91-a80b-18b8e7fa3de2, #fa0043);
    color: white;
    font-size: 2rem;
    border: none;
    display: block;
    color: var(--token-dcfc1000-f350-494d-bd15-7f017b76226e, #ffffff) /* {"name":"white"} */;
    font-size: 18px;
    margin-left: auto;
    margin-right: auto;  
  }