
.Imagetour{
    padding-left: 10px;
    padding-right: 10px;
}
.zxcv{
    display: flex;
    color: rgb(120, 144, 161);
    justify-content:space-between;
}
.titletournamentcard{
   color: rgb(22, 159, 255);
   font-size: 15px;
    font-weight: 500;
}


.border_Color_box{
    /* border: 1px solid #23354d !important; */
    border-bottom: 1px solid #23354d !important;
    padding-top: 18px;
}

.cardcompo{
    background-color:rgb(16, 20, 44);
}


.yyyy{
    color: white;
    font-size: 4rem;
    margin-right: 20px;
}
.stadiumnamecardtour{
    color: white;
    font-size: 2.5rem;
   margin-top: -2px; 
}
.tournamentdetailscardtour{
  
   
    overflow: hidden;
    white-space: pre-wrap;
    word-wrap: break-word;
    word-break: break-word;
    font-family: "Inter", sans-serif;
    color:  #7890a1;
    font-size: 13px;
    letter-spacing: 0px;
    line-height: 1.2;
}
.btndiv{
    display: flex;
    justify-content: space-between;
}
.btnparticipate{
    background-color: #fE6666;
    border: none;
    border-radius: 25px;
    color: white;
    font-weight: 500;
    padding: 10px;
    width: 120px;
    /* margin-left: 10px; */
    align-items: center;
    /* padding: 3px 21px 3px 21px; */
    background-color:  #fa0043;
    overflow: visible;
    border-radius: 52px;
}
.btnmoredetail{
    border: none;
    background: none;
    color: rgb(22, 159, 255);
    margin-right: 10px;

}
/* .alignrightt{
    font-size: 20px;
    letter-spacing: 0px;
    line-height: 1.5;
} */

.alignrightt2{
    white-space: pre;
    font-weight: 400;
    font-style: normal;
    font-family: "Noto Sans", sans-serif;
    color: #ffffff;
    font-size: 14px;
    letter-spacing: 0px;
    line-height: 1.2;
}
.alignrightt3{
    white-space: pre;
    font-weight: 400;
    font-style: normal;
    font-family: "Noto Sans", sans-serif;
    color:  #7890a1;
    font-size: 11px;
    letter-spacing: 0px;
    line-height: 1.2;
}

.alignrightt4{
    font-family: "Inter", sans-serif;
    color:  #7890a1;
    font-size: 12px;
    letter-spacing: 0px;
    line-height: 1.5;
}
.matelicn{
    color: gray !important;
}
/* .padding_FromHeader{
    padding-top: 120px;
} */

@media only screen and (max-width: 600px) {
   .btnparticipate{
       width: 100px;
       padding: 5px;
       font-size: 12px;
       /* margin-left: 10px; */
   }
   .btnmoredetail{
       font-size: 12px;
   }
.yyyy{
    font-size: 15px;
    margin-top: 5px;
}

  }