.box__dragndrop,
.box__uploading,
.box__success,
.box__error {
  display: none;
}
.box.has-advanced-upload {
    background-color: white;
    outline: 2px dashed black;
    outline-offset: -10px;
  }
  .box.has-advanced-upload .box__dragndrop {
    display: inline;
  }
  .box__file{
      height: 10vh !important;
  }