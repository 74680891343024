.shadow13 { 
    box-shadow: 0 5px 10px rgba(154,160,185,.05), 0 15px 40px rgba(166,173,201,.2);
  }

  .darto-l2{
    width: 100%;
    border-radius: 5px;
    font-size: 3rem;
  }
  .Tabletournament{
    font-size: 2rem;
    margin-top: 40px;
  }
  .listing-tournament{
    font-size: 14px;
    float: right;
  }
  a {
    text-decoration: none;
    display: inline-block;
    /* padding: 8px 16px; */
  }
  
  /* a:hover {
    background-color: #ddd;
    color: black;
  } */
  
  .previous {
    background-color: #f1f1f1;
    color: black;
  }
  
  .next {
    background-color: gray;
    color: white;
  }
  
  /* .round {
    border-radius: 50%;
  } */
  .score-display{
    font-size: 14px;
    
 
  }
  .score-display1{
    font-size: 20px;
  }
  .score-dis-flex{
    margin-top: 10px;
    margin-bottom: 10px;
    
    display: flex;
  }
  .score-dis-flex1{
    width: 25vw;
    padding: 10px;
    background-color: gray;
    color: white;
    border: none;
    border-radius: 10px;
  }
  .p-margin-top{
    margin-top: 7px;
  }
  .float-right{
    float: right;
  }