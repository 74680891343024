.stake-main-update {
    flex-shrink: 0;
    height: 100vh;
    width: 100%;
   padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    overflow: visible;
  }
  .stake-back {
    flex-shrink: 0;
    width: 100%;
    /* height: 36px; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    overflow: visible;
  }
  .stack-back1 {
    box-sizing: border-box;
    width: auto; /* 104px */
    flex-shrink: 0;
    height: 36px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 0px 6px 0px;
    overflow: visible;
  }
  .update-opt {
    width: auto; /* 71px */
    height: auto; /* 22px */
    flex-shrink: 0;
    overflow: visible;
    white-space: pre;
    font-weight: 400;
    font-style: normal;
    font-family: "Noto Sans", sans-serif;
    color: #96a8b6;
    font-size: 25px;
    letter-spacing: 0px;
    line-height: 1.2;
  }
  .darto-logo5 {
    flex-shrink: 0;
    width: 99px;
    /* height: 36px; */
    overflow: visible;
  
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  .darto-about1{
    color:  #ffffff;
    font-size: 14px;
    padding: 10px;
  }
  .darto-about2{
    color: #0af;
  font-size: 14px;
  padding: 10px;
  }
  .list-serach2{
    padding: 20px;
    margin-top: 10px;
  }