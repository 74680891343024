.stake-avator {

    border-color:  #23354d ;
    border-style: solid;
    border-left: none;
    border-right: none;
    border-top: none;
    padding-top: 18px;
   
  }

  .stacks1{
    height: auto; /* 27px */
    flex-shrink: 0;
    width: 100%;
 
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    overflow: visible;
  }
.textadmin{
    width: auto; /* 178px */
    height: auto; /* 14px */
    flex-shrink: 0;
    overflow: visible;
    /* white-space: pre; */
    font-weight: bold;
    font-style: normal;
    font-family: "Noto Sans", sans-serif;
    color: var(--blue_highlight, #00bbff);
    font-size: 12px;
    letter-spacing: 0px;
    line-height: 1.2;
    text-transform: uppercase;
    text-align: left;
}
.stack-darto{
   
    width: 79px; /* 79px */
    flex-shrink: 0;
    height: 27px;
    display: flex;
    float: right;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  
    
    border-radius: 15px;
    border: 2px solid  #00bbff /* {"name":"blue_highlight"} */;
}
.adminbutton{
    width: 79px; /* 37px */
    height: 27px;
    border-radius: 15px;
    border: 2px solid  #00bbff ;
    flex-shrink: 0;
    overflow: visible;
    white-space: pre;
    font-weight: 400;
    font-style: normal;
    font-family: "Noto Sans", sans-serif;
    color: var(--blue_highlight, #00bbff);
    font-size: 12px;
    letter-spacing: 0px;
    background-color: #10142c ;
    line-height: 1;
    text-align: center;
}
.team-one{
    box-sizing: border-box;
    height: auto; /* 90px */
    flex-shrink: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0px 0px 9px 0px;
    overflow: visible;
   
  
  
}
.team-d-p{
    box-sizing: border-box;
    flex-shrink: 0;
    width: 192px;
    height: 108px;
    /* overflow: visible; */
    /* background-image: url("../../../assets/images/logo_files/imgn.jpg"); */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 6px;
    /* border: 2px solid var(--token-105cd1bb-da93-4dec-9132-daecc8454f68, #23354d) ; */
}
.stake-team-details {
    flex: 1;
    width: 1px;
    margin-left: 10px;
    flex-shrink: 0;
    height: 65px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    overflow: visible;
    border-radius: 6px;
  }
  .total-members-10 {
    width: auto; /* 108px */
    height: auto; /* 14px */
    flex-shrink: 0;
    overflow: visible;
    /* white-space: pre; */
    font-weight: 400;
    font-style: normal;
    font-family: "Noto Sans", sans-serif;
    color: var(--grey2, #7890a1);
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 1.2;
    text-transform: capitalize;
  }
  .stackline{
    /* width: 100%;  */
    flex-shrink: 0;
    /* height: 19px; */
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    overflow: visible;
  }
  .display_Line_End{
    align-self: self-end;
    display: flex;
  }
 
  .curser-pointer{
    cursor: pointer;
  }
 .modal-header{
   font-size: 12px !important;
   background-color: #10142c !important;
   color: white !important;
   
   border: none !important;
   justify-content: right !important;
 }
 .image-modal-body{
   padding-top: 0px !important;
   padding-left: 20px  !important;
   padding-right: 20px !important;
   background-color: #10142c !important;
   padding-bottom: 20px !important;
 }
 .cross-color-modal{
   padding-right: 10px !important;
   border: none !important;
   background-color: #10142c !important;
 }