.card_Body_BackGround{
    background-color: #022452 !important;
}
.class_text_27px{
font-size: 20px !important;
color: #0aa0d5;
}
.class_text_14px{
font-size: 14px;
color: #7890a1;
}
.padding_left_18px_right{
    padding-left: 20px;
    padding-right: 20px;
}
.padding_9px{
    padding: 9px;
}
.padding_99px_from_footer{
    padding-bottom: 99px;
}