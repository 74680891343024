 .connect1{
     height: 100vh;
 padding: 20px;
  
  
} 
.divbtnconnect{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    
}
.btnconnect{
    background-color: #23354d;
    height: 40px;
    border: none;
    border-radius: 5px;
    font-size: 3rem;
    color: lightslategray;
    width: 100%;
}
.connecticons{
    color:  rgb(22, 159, 255);
    font-size: 25px;
    margin-left: 10px;
}
.header-df-all{
 
        width: auto; /* 82px */
        height: auto; /* 12px */
        flex-shrink: 0;
        overflow: visible;
        white-space: pre;
        font-weight: 400;
        font-style: normal;
        font-family: "Noto Sans", sans-serif;
        color:  #7890a1;
        font-size: 12px;
        letter-spacing: 0px;
        line-height: 1;
        text-align: left;
      
}