.screen-home-payment {
  box-sizing: border-box;
  width: 100%;

 
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding:20px;
  background-color: #10142c;
  overflow: hidden;
  border-radius: 10px;
}
.payment-plans-head {
  width: auto; /* 126px */
  height: auto; /* 19px */

  overflow: visible;
  white-space: pre;
  font-weight: 400;
  font-style: normal;
  font-family: "Noto Sans", sans-serif;
  color:  #7890a1;
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 1.2;
}
.stack-paymnet-plant {
  width: 100%;

  /* height: 220px; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* align-items: center; */
  margin-top: 10px;
  overflow: visible;
}
.select-your-plans {
  width: auto; /* 116px */
  height: auto; /* 17px */
  overflow: visible;
  margin-top: 22px;
  white-space: pre;
  font-weight: 400;
  font-style: normal;
  font-family: "Noto Sans", sans-serif;
  color:  #ffffff;
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 1.2;
  text-align: left;
}
.stack-your-plan-paymnt {
  flex-shrink: 0;
  /* width: 310px; */
  height: 76px;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  overflow: visible;
}
.frame-stack-your-plan-paymnt {
  flex-shrink: 0;
  text-align: center;
  width: 56px;
  height: 72px;
  overflow: visible;
}
.round-paymt {
  box-sizing: border-box;
  width: 56px;
  height: 56px;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px hsla(0, 0%, 0%, 0.24);
  background-color: #fa0043;
  overflow: visible;
  border-radius: 38px;
}
.std-pyt {
padding: 20px;
  white-space: pre;
  font-weight: 700;
  font-style: normal;
  font-family: "Noto Sans", sans-serif;
  color:  #ffffff;
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 1;
}
.standard-define {
  width: auto; /* 45px */
  height: auto; /* 10px */
  overflow: visible;
  white-space: pre;
  font-weight: 700;
  font-style: normal;
  margin-top: 10px;
  font-family: "Noto Sans", sans-serif;
  color: #00bbff;
  font-size: 10px;
  letter-spacing: 0px;
  line-height: 1;
}
.round-paymt-premium {
  box-sizing: border-box;
  width: 56px;
  height: 56px;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px hsla(0, 0%, 0%, 0.24);
  background-color:  #7890a1;
  overflow: visible;
  border-radius: 38px;
}
.frame-df-all {
  /* flex-shrink: 0; */
  /* width: 309px; */
  height: 58px;
  margin-top: 40px;
  /* overflow: visible; */
  display: flex;
}
.round-all-d{
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  margin-top: 10px;
  overflow: visible;
  border-radius: 50%;
  border: 2px solid  #fa0043;
}
.round-d-al-us {
  width: 10px;
  height: 10px;
  background-color:  #fa0043;
  overflow: visible;
  border-radius: 50%;
}
.month-df-h {
  width: 100%;
  height: 35px;
  overflow: visible;
}
._1-roun {
  width: auto; /* 19px */
  height: auto; /* 44px */
  overflow: visible;
  white-space: pre;
  font-weight: 700;
  font-style: normal;
  font-family: "Noto Sans", sans-serif;
  color:  #00bbff;
  font-size: 36px;
  letter-spacing: 0px;
  line-height: 1.2;
}
.month-div-1 {
  width: auto; /* 43px */
  height: auto; /* 17px */
  overflow: visible;
  white-space: pre;
  font-weight: 400;
  font-style: normal;
  font-family: "Noto Sans", sans-serif;
  color: #00bbff;
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 1.2;
  text-align: left;
}
.value-for-money {
  width: auto; /* 90px */
  height: auto; /* 13px */
  overflow: visible;
  white-space: pre;
  font-weight: 700;
  font-style: normal;
  font-family: "Noto Sans", sans-serif;
  color:  #00bbff;
  font-size: 11px;
  letter-spacing: 0px;
  line-height: 1.2;
  text-align: right;
}
._10-dd {
  width: auto; /* 47px */
  height: auto; /* 29px */
  overflow: visible;
  white-space: pre;
  font-weight: 700;
  font-style: normal;
  font-family: "Noto Sans", sans-serif;
  color: #00bbff;
  font-size: 24px;
  letter-spacing: 0px;
  line-height: 1.2;
}
.stack-all-time {
  /* flex-shrink: 0; */
  width: 384px;
  height: 248px;
  display: flex;
  margin-top: 40px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: visible;
}
.features-all-time {
  width: auto; /* 62px */
  height: auto; /* 17px */
  flex-shrink: 0;
  overflow: visible;
  white-space: pre;
  font-weight: 700;
  font-style: normal;
  font-family: "Noto Sans", sans-serif;
  color: #00bbff;
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 1.2;
  text-align: left;
}
.features-list1 {
  box-sizing: border-box;
  flex-shrink: 0;
  width: 100vw;
  height: 9px;
  margin-top: 20px;
  overflow: visible;
  border-color: #DDDDDD;
  border-style: solid;
  border-top-width: 1px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.features-list1-define {
  margin-top: 10px;
  flex-shrink: 0;
  display: flex;
  width: 240px;
  height: 34px;
  overflow: visible;
}
.no-watermark {
  width: auto; /* 96px */
  height: auto; /* 17px */
  overflow: visible;
  margin-left: 10px;
  white-space: pre;
  font-weight: 400;
  font-style: normal;
  font-family: "Noto Sans", sans-serif;
  color:  #ffffff;
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 1.2;
}
.round-buy-bt {

  /* width: 384px; */
  height: 36px;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px hsla(0, 0%, 0%, 0.24);
  background-color:  #00bbff;
  overflow: visible;
  border-radius: 30px;
  border: none;
  text-align: center;
  width: 315px;
  white-space: pre;
  font-weight: 700;
  font-style: normal;
  font-family: "Noto Sans", sans-serif;
  color: #000000;
  font-size: 16px;
  padding: 10px;
  letter-spacing: 0px;
  line-height: 1;
  margin-top: 30px;
}
.mt_Color_D{
  color: rgb(255, 255, 255) ;
}
.mt_Color_D1{
  color: rgb(120, 144, 161);
}
/* }
.buy-standard-plan {

  width: 315px;
  white-space: pre;
  font-weight: 700;
  font-style: normal;
  font-family: "Noto Sans", sans-serif;
  color: #000000;
  font-size: 16px;
  padding: 10px;
  letter-spacing: 0px;
  line-height: 1;
} */