
.mobile-Header {
    height: 8vh; 
    width: 100%; 
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    overflow: visible;
    background-color: #202337;
    position: fixed;
    left: 0;
    bottom: 0;
    padding-top: 3px;
    padding-bottom: 12px;
    padding-left: 20px;
    padding-right: 20px;

   
  }
  .mobile-Headered {
   
    width: 100%; 
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    align-items: center;
    overflow: visible;
   
    position: fixed;
    left: 0;
 top:0;

    padding-bottom: 12px;
    padding-left: 20px;
    padding-right: 20px;

   
  }
  ._1-frame-home {
    height: auto; /* 35px */
    flex-shrink: 0;
    width: 45px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /* margin-bottom: 10px; */
    align-items: center;
    overflow: visible;

  }
  .home-row-fun {
    width: auto; /* 25px */
    height: auto; /* 9px */
    justify-content: space-around;
    
   /* margin-top: 5px; */
    white-space: pre;
    font-weight: 500;
    font-style: normal;
    font-family: "Roboto", sans-serif;
    color: #5c778a;
    font-size: 12px;
    letter-spacing: 0px;
    line-height: 1;
    text-align: center;
  }
  .home-row-fun:hover{
      color: #00bbff;
  }
  .home-row-fun:active{
    color: #00bbff;
}
.active{
  color: #00bbff;
}
@media screen and (max-width: 500px) {
    .navbar1 li {
      float: none;
      display: none;
    }
  }