.clearicon{
   position: absolute;

}
.iconStyle{
    width: 20px;
    height: 20px;
}
.image_modal1{
 
    font-size: 14px !important;
    padding: px;
}
.image_modal{
    font-size: 14px !important;
}