.company-logos {
    box-sizing: border-box;
    width: 100%;
    flex-shrink: 0;
    height: 234px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow: visible;
    border-color: var(--token-105cd1bb-da93-4dec-9132-daecc8454f68, #23354d) /* {"name":"Search"} */;
    border-style: solid;
    border-top-width: 0px;
    border-bottom-width: 1px;
    border-left-width: 0px;
    border-right-width: 0px;
  }
  .company-logos2 {
    box-sizing: border-box;
    flex-shrink: 0;
    width: 100px;
    height: 60px;
    overflow: visible;
 
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  .iconsrow{
    width: 100%;
  }
  .companyL_0_G_O{
    width: 80px;
    /* height: 60px; */
  }
  .justify_Content_flex_start{
    justify-content: start !important;
  }