
    .all-profile-details{
        /* 649px */
    
        width: 100%;
        height: 100%;
        display: flex;
        padding: 10px;
      
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
      
      }
      .all-profile-details1{
        width: 100vw;
        flex-shrink: 0;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        overflow: visible;
      }
      .profile-avatar-darto {
        flex-shrink: 0;
        width: 111px;
        height: 72px;
        overflow: visible;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
      .mydarto-button {
        width: 100%;
        flex-shrink: 0;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        overflow: visible;
      }
      .mydarto-button1 {
        box-sizing: border-box;
        flex-shrink: 0;
        /* width: 124px; */
        height: 36px;
        display: flex;
     
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 20px;
        box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px hsla(0, 0%, 0%, 0.24);
        background-color: var(--token-e2079bf0-f281-429e-90ac-e5958a3c1a75, #23354d);
        overflow: visible;
        color: white;
        font-size: 2rem;
        border-radius: 38px;
      }
      .mydarto-button1:hover{
        background-color: var(--token-eff61df6-31e2-4b91-a80b-18b8e7fa3de2, #fa0043);
      }
      .mydart-style{
       
            width: 100%;
            flex-shrink: 0;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            overflow: visible;
       
      }
      .titles-won {
        width: auto; /* 68px */
        height: auto; /* 14px */
        flex-shrink: 0;
        overflow: visible;
        white-space: pre;
        font-weight: 400;
        font-style: normal;
        font-family: "Noto Sans", sans-serif;
        color: var(--token-a38dd3d3-b9de-4302-b504-277e6b4a701f, #7890a1) /* {"name":"grey2"} */;
        font-size: 14px;
        letter-spacing: 0px;
        line-height: 1;
        text-transform: capitalize;
        text-align: left;
      }
      .profile-avatar-edi {
        box-sizing: border-box;
        width: 100%;
        flex-shrink: 0;
        height: 100%;
        padding: 20px;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
       
        overflow: visible;
        border-color: var(--token-105cd1bb-da93-4dec-9132-daecc8454f68, #23354d) /* {"name":"Search"} */;
        border-style: solid;
        border-top-width: 0px;
        border-bottom-width: 1px;
        border-left-width: 0px;
        border-right-width: 0px;
      }
      .tournament-name {
        width: auto; /* 142px */
        height: auto; /* 19px */
        flex-shrink: 0;
        overflow: visible;
        white-space: pre;
      
        font-weight: 400;
        font-style: normal;
        font-family: "Noto Sans", sans-serif;
        color: var(--token-d983c132-e015-4177-baf8-441dbe84f948, #00bbff) /* {"name":"blue_highlight"} */;
        font-size: 16px;
        letter-spacing: 0px;
        line-height: 1.2;
        text-transform: capitalize;
        text-align: left;
      }
      .winner-style {
        width: 100%;
        flex-shrink: 0;
        height: 15px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        overflow: visible;
      }
      .winner-style2 {
        width: auto; /* 48px */
        height: auto; /* 16px */
        flex-shrink: 0;
        overflow: visible;
        white-space: pre;
        font-weight: 400;
        font-style: normal;
        font-family: "Noto Sans", sans-serif;
        color: var(--token-dcfc1000-f350-494d-bd15-7f017b76226e, #ffffff) /* {"name":"white"} */;
        font-size: 14px;
        letter-spacing: 0px;
        line-height: 1.2;
        text-transform: capitalize;
        text-align: left;
      }
      .date-20-dec-2021 {
        width: auto; /* 97px */
        height: auto; /* 12px */
        flex-shrink: 0;
        overflow: visible;
        white-space: pre;
        font-weight: 400;
        font-style: normal;
        font-family: "Noto Sans", sans-serif;
        color: var(--token-a38dd3d3-b9de-4302-b504-277e6b4a701f, #7890a1) /* {"name":"grey2"} */;
        font-size: 12px;
        letter-spacing: 0px;
        line-height: 1;
        text-transform: capitalize;
        text-align: left;
      }
      .border-botton1{
        width: 100%;
      }
   