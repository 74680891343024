.Commoncardpink1{
    width: auto; /* 311px */
    height: auto; /* 20px */
    flex-shrink: 0;
    overflow: visible;
    white-space: pre;
    font-weight: 400;
    font-style: normal;
    font-family: "Noto Sans", sans-serif;
    color: white;
    font-size: 16px;
    letter-spacing: 0px;
    line-height: 1.2;
    text-align: left;
  
    margin-top: -5px;
    padding-top: 20px;
    }
    
    .colourqw1{
        background-color: #10142c;
    }
    .styleHeight{
        width: 100%;
    
    }
    .padding-t0p-bottom-10px{
        height: 160px;
     padding: 10px;
        background-color: #233b64;
        
    }
    .text-align-center-mid{
  text-align: center;

    /* width: 100%; */
    /* height: 8vh; */
    /* display: block; */
    margin-left: auto;
    /* margin-right: auto; */
    /* background-color: red; */
    /* margin-top: 9px; */
    /* margin-bottom: 10px; */
    color: white;
    font-size: 20px;
    background-color: #fa0043;
    border: none;
    padding: 10px;
    }
    