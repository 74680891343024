.spn{
    color: white;  
  }
  .amigo{
    font-weight: 700;
    font-style: normal;
    font-family: "Noto Sans", sans-serif;
    color:  #00bbff;
    font-size: 15px;
    letter-spacing: 0px;
    line-height: 1.5;
 
  }
  .rint{
  
      display: inline-block;
  }
  .txtcolor1st{
    color:  #7890a1;
      font-size: 13px;
     
  }
  .txtcolor2nd{
      color: gray;
      font-size: 13px;
      padding-left: 20px; 
  }
  .spn1{
      color: var(--grey2, #7890a1);
      font-size: 15px; 
      float: right;
    }
    .button-container-div12 {
      width: 50vw;
      height: 2vh;
      margin: auto;
  }
  .txtcolor3b{
      color: var(--grey2, #7890a1);
      font-size: 12px;
  }
  .mdshare{
      color: var(--grey2, #7890a1); 
      float: right;
  }
  .txtcolor34{
    color: gray;
    font-size: 13px;
}
.underlined{
    border-bottom: 1px solid #23354d;
}
.workinghour{
    color: #7890a1;
  font-size: 10px;
 margin-top: 20px !important;
}
.spn12{
    color: var(--greay, #b5b5b5);
    font-size: 12px;
}
.workinghour1{
  white-space: pre;
  font-family: "Inter", sans-serif;
  color:  #7890a1;
  font-size: 10px;
  letter-spacing: 0px;
  line-height: 1.2;

}
.workinghour2{
  font-weight: 400;
  font-style: normal;
  font-family: "Noto Sans", sans-serif;
  color: #7890a1;
  font-size: 12px;
  letter-spacing: 0px;
  line-height: 1.2;
  display: flex;
  
}
/* .img_SPECIFIC_Height{
  height: 236px !important;
} */
/* .card-body{
  padding: 9px  !important;
} */
.card_Body_none{
  padding: 0px !important;
  border-bottom: 1px solid #23354d;
}