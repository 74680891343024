/* .settingmaindiv{
    background-color:rgb(16, 20, 44);
    padding-top: 12%;
    padding-bottom: 20%;

}
.settingdiv{
    display: flex;
margin-top: 20px;
    justify-content: center;
    background-color:rgb(16, 20, 44);

    
}

.settingdiv1{
    width: 30%;
}
.fontsettinggrey{
    color: rgb(133, 124, 124);
    font-size: 18px;
    
}
.settingdiv2{
    width: 50%;
    margin-left: 100px;
}
.btnsetting{
    width: 70px;
    color: black;
    margin-left: 5px;
    border: none;
    border-radius: 25px;
}
.btnsettingpink{
    width: 150px;
    color: black;
    margin-left: 5px;
    border: none;
    border-radius: 25px;
    background-color: rgb(168, 40, 83);

}
.dropdownsetting{
    width: 200px;
}
.select-all-div{
     width: 100%;
  flex-shrink: 0;
  height: 578px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: visible;
}
@media only screen and (max-width: 600px) {
.fontsettinggrey{
    margin-left:20px;
}
.settingmaindiv{
    padding-top: 24%;
    padding-bottom: 40%;
}


    
  } */
  .setting-page{
   
        width: 100%;
        flex-shrink: 0;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        overflow: visible;
        padding: 20px;
 
  }
  .frame-setting {
    width: 100%;
    flex-shrink: 0;
    height: 48px;
    display: flex;
    overflow: visible;
  }
  .language-setting {
    width: 100%; /* 62px */
    height: auto; /* 17px */
    overflow: visible;
    white-space: pre;
    font-weight: 400;
    font-style: normal;
    font-family: "Noto Sans", sans-serif;
    color:  #7890a1;
    font-size: 14px;
    letter-spacing: 0px;
    line-height: 1.2;
  }
  .frame-setting-2 {
    width: 100%;
    flex-shrink: 0;
    height: 48px;
    overflow: visible;
  }
  .theme-setting-2 {
    width: 100%; /* 45px */
    height: auto; /* 17px */
    overflow: visible;
    white-space: pre;
    font-weight: 400;
    font-style: normal;
    font-family: "Noto Sans", sans-serif;
    color:  #7890a1;
    font-size: 14px;
    letter-spacing: 0px;
    line-height: 1.2;
  }
  .round-setting-7 {
    width: 180px;
    height: 30px;
    background-color:  #b5b5b5;
    overflow: visible;
    border-radius: 10px;
    border: none;
    font-weight: 400;
    font-style: normal;
    font-family: "Noto Sans", sans-serif;
    color:  #000000;
    font-size: 14px;
    letter-spacing: 0px;
    line-height: 1.2;
    text-align: center;
  }
  .round-setting-1 {
    width: 87px;
    height: 30px;
    background-color:  #b5b5b5;
    overflow: visible;
    border-radius: 24px;
    font-weight: 400;
    font-style: normal;
    font-family: "Noto Sans", sans-serif;
    color:#000000;
    font-size: 14px;
    letter-spacing: 0px;
    line-height: 1.2;
    text-align: center;
    border: none;
    /* color: white;
    font-weight: bold; */
  }
  .round-setting-1 :hover{
    background-color:  #00bbff;
  }
  .light-setting-1 {
    width: 33px; /* 33px */
    height: auto; /* 17px */
    overflow: visible;
    white-space: pre;
    font-weight: 400;
    font-style: normal;
    font-family: "Noto Sans", sans-serif;
    color:  #000000;
    font-size: 14px;
    letter-spacing: 0px;
    line-height: 1.2;
    flex-direction: row;
        justify-content: center;
        align-items: center;
  }
  .light-setting-2{
  
        box-sizing: border-box;
        width: 100%; /* 188px */
        height: 30px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 6px 40px 6px 40px;
        background-color:  #fa0043;
        overflow: visible;
        border-radius: 24px;
       border: none; 
  }
  .light-setting-3{
  

    width: 150px; /* 188px */
    /* height: 30px; */
    padding: 8px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-style: normal;
    font-family: "Noto Sans", sans-serif;
    color:  #ffffff;
    font-size: 14px;
    letter-spacing: 0px;
    line-height: 1.2;
    text-align: center;
    font-weight: bold;
    /* padding: 6px 40px 6px 40px; */
    background-color:  #fa0043;
    overflow: visible;
    border-radius: 24px;
    border: none; 
}
  