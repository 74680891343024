.padding_top_customer{
 background-image: url("darto_web_bg2.svg");
 width: 100%;
 height: 100%;
 background-position: right;

 background-repeat: no-repeat;
 background-size: cover;


}
.customer-position-position{
  padding-top: 27px;
  
}
.customer-align-center{
  display: flex;  
}
.margin-auto-with-img{
   
    margin: auto;
 
}
.customer-engagement{
    color: white;
    font-size: 24px;
    font-weight: lighter;
    text-align: center;
}
.parra-customer{
    line-height: 2;
    font-size: 13px;
    color: white;
    text-align: center;
    padding: 10px;
}
.padding-top-18px-left-right{
    padding-top: 9px;
    padding-left: 10px;
    padding-right: 10px;
}
.padding-left-right-10px{
    padding-left: 30px !important;
    padding-right: 30px !important;
}
.padding-10px-left-right-tournament{
    padding-left: 10px !important;
    padding-right: 10px !important;
}
.padding-10px{
    
    padding: 10px;
}